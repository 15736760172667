<template>
	<div class="drawBox">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字: </label>
				<el-select v-model="keyId" placeholder="请选择" style="width: 120px;margin-right: 10px;">
					<el-option v-for="(item,index) in keyList" :key="index" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
				<el-input v-model="searchKey" :placeholder="keyId==1?'订单编号':'员工姓名、手机号'" style="width: 200px;" clearable></el-input>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions'></el-date-picker>
			</div>

		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">门店标签: </label>
				<el-select v-model="ShopTagId" placeholder="请选择" filterable clearable style="width:150px;margin-right: 10px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in LabelList" :key="index" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">归属门店: </label>
				<el-select v-model="ShopId" placeholder="请选择" filterable clearable style="width:150px;margin-right: 10px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in dropList" :key="index" :label="item.ShopName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">提成状态: </label>
				<el-select v-model="StateId" placeholder="请选择" filterable clearable style="width:120px;margin-right: 10px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in StateList" :key="index" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>

			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>

		</div>
		<div class="totalStyle">提成总额：{{AwardMoneyAll}}</div>
		<el-table :data="tableData" style="width: 100%" v-loading="loading">
			<el-table-column prop="ShopName" label="员工" width="120">
				<template slot-scope="scope">
					<div>{{scope.row.EmployeeName}}</div>
					<div>{{scope.row.Phone}}</div>
				</template>
			</el-table-column>
			<el-table-column label="归属门店" width="130">
				<template slot-scope="scope">
					<div class="ellipsisCommon">{{scope.row.ShopName}}</div>
				</template>
			</el-table-column>
			<el-table-column label="订单" width="130">
				<template slot-scope="scope">
					<div style="color:red">[{{scope.row.SendTypeValue}}]</div>
					<div style="color:#409EFF;cursor:pointer;" @click='pushTorderdetail(scope.row)'>{{scope.row.OrderNo}}</div>
				</template>
			</el-table-column>
			<el-table-column label="商品" width="180">
				<template slot-scope="scope">
					<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" placement="top-start">
						<div slot="content">
							<div>{{scope.row.ProductName}}</div>
							<!-- <div style="color:#909399;;">{{scope.row.Barcode}}</div> -->
						</div>
						<div>
							<div class="ellipsisCommon">{{scope.row.ProductName}}</div>
							<!-- <div style="color:#909399;;">{{scope.row.Barcode}}</div> -->
						</div>
					</el-tooltip>

				</template>
			</el-table-column>
			<el-table-column label="规格" width="150">
				<template slot-scope="scope">
					<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" placement="top-start">
						<div slot="content">
							<div class="oneSpless">
								{{scope.row.SpecValue}}
								<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
							</div>
							<div style="color:#909399;">{{scope.row.Barcode}}</div>
						</div>
						<div>
							<div class="oneSpless ellipsisCommon">
								{{scope.row.SpecValue}}
								<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
							</div>
							<div style="color:#909399;">{{scope.row.Barcode}}</div>
						</div>
					</el-tooltip>

				</template>
			</el-table-column>
			<el-table-column label="数量" prop="ProductCount"></el-table-column>
			<el-table-column label="提成比">
				<template slot-scope="scope">
					<div>{{scope.row.CommissionRateValue}}</div>
				</template>
			</el-table-column>
			<el-table-column label="提成">
				<template slot-scope="scope">
					<div>{{scope.row.AwardMoney}}</div>
					<div>{{scope.row.StateValue}}</div>
				</template>
			</el-table-column>
			<el-table-column label="提成时间" width="150">
				<template slot-scope="scope">
					<div>{{scope.row.AddTime}}</div>
				</template>
			</el-table-column>
			<el-table-column label="提成备注" width="120">
				<template slot-scope="scope">
					<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" placement="top-start">
						<div slot="content">
							<div style="width: 120px;">{{scope.row.Remark}}</div>
						</div>
						<div class="ellipsisCommon" style="-webkit-line-clamp: 4;line-clamp:4">{{scope.row.Remark}}</div>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: right;">
			<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		shopdroplist
	} from '@/api/api'
	import {
		// shopPerformanceindex,
		shopPerformanceInt
	} from '@/api/goods'
	import {
		commissionlist
	} from '@/api/TurnTomySelf'

	export default {
		data() {
			return {
				AwardMoneyAll: 0,
				exportUrl: config.EXPORT_URL,
				keyId: 1,
				keyList: [{
					Text: '订单编号',
					Value: 1
				}, {
					Text: '员工',
					Value: 2
				}],
				searchKey: '',
				starTime: '',
				endTime: '',
				LabelList: [],
				ShopTagId: null,
				dropList: [],
				ShopId: null,
				StateId: null,
				StateList: [{
					Text: '待结算',
					Value: 0
				}, {
					Text: '已结算',
					Value: 1
				}, {
					Text: '已失效',
					Value: 2
				}],
				tableData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				goUrls: config.GO_URL,

			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.starTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			}
		},
		beforeMount() {
			this.keyId = this.$route.query.keyId ? Number(this.$route.query.keyId)  : 1;
			this.searchKey = this.$route.query.employPhone ? this.$route.query.employPhone : '';
			this.getShopdroplist()
			this.getStoreLabel()
			this.getList()
		},
		methods: {
			//跳转到订单详情
			pushTorderdetail(record) {
				let OrderNoId = record.OrderNo ? record.OrderNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + (record.OrderFrom==0?'/order/orderDetailpage':'/videoShop/videoOrderDetails')+'?Id=' + record.OrderNo

				window.open(url)
			},
			//获取数据
			async getList() {
				this.loading = true
				try {
					let data = {
						KeyWordsType: this.keyId,
						KeyWords: this.searchKey,
						ShopTagId: this.ShopTagId,
						ShopId: this.ShopId,
						StartTime: this.starTime,
						EndTime: this.endTime,
						State: this.StateId,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}
					let result = await commissionlist(data)
					this.AwardMoneyAll = result.Result.AwardMoneyAll
					this.Total = result.Result.Total;
					this.tableData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			handleFilter() {
				this.currentPage = 1
				this.getList();
			},
			// 导出
			async exportFun() {
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/employeePerformance/commissionRecord?' +
						'&KeyWordsType=' + this.keyId +
						'&KeyWords=' + this.searchKey +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
						'&ShopId=' + (this.ShopId ? this.ShopId : '') +
						'&State=' + (this.StateId ? this.StateId : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			async getShopdroplist() {
				try {
					let doorResult = await shopdroplist({
						IsOpenRoleJurisdiction: true
					})
					this.dropList = this.dropList.concat(doorResult.Result)
				} catch (e) {}
			},
			async getStoreLabel() {
				try {
					let result = await shopPerformanceInt({})
					this.LabelList = result.Result.ShopTagSelectList
					console.log(this.LabelList, 88)
				} catch (err) {

				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>
<style lang="less" scoped>
	.drawBox {
		background: #fff;
		padding: 15px;

		.totalStyle {
			background: #F5F5F5;
			padding: 13px;
			font-size: 15px;
		}

		.ellipsisCommon {
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
</style>
